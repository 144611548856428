@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.testimonial-card {
  padding: 16px;
  @include flex-v-center;
  flex-direction: column;
  text-align: center;

  h3 {
    width: 100%;
    font-size: 30px;
    line-height: 1.35;
    margin-bottom: 20px;

    @include media(tablet) {
      font-size: 24px
    }

    @include media(mobile-l) {
      font-size: 20px
    }
  }

  blockquote {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.68;
    letter-spacing: 0.03em;
  }

  img {
    width: 76px;
    height: 76px;
    border-radius: calc(var(--radius-main) * 8 - 4px);
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px;
  }

  cite {
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
  }

  &--expert {
    img {
      margin-bottom: 30px;
      object-position: top;
    }
  }

  &--rate {
    position: relative;
    max-width: 525px;
    padding: 0;
    padding-bottom: 35px;
    width: 100%;

    &::before {
      @include pseudo;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid var(--color-black);
    }

    img {
      height: 120px;
      width: 120px;
      margin-bottom: 0;
      object-position: center;
      z-index: 1;
    }

    object {
      margin-bottom: 25px;
    }

    h3 {
      margin-bottom: 15px;
    }

    blockquote {
      padding-inline: 15px;
      margin-bottom: 20px;
    }
  }
}