@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  font-size: 18px;
  background-color: var(--color-dark);

  &__top {
    padding-block: 37px;
  }

  .copyright {
    font-size: 16px;
    font-weight: var(--fw-300);

    @include media(mobile-s) {
      max-width: 250px;
      font-size: 14px;
      text-align: center;
    }
  }

  &__bottom {
    background-color: var(--color-black);
    padding-block: 27px;

    p {
      color: var(--color-white);
      font-family: "Inter", sans-serif;
      font-size: 16px;
      letter-spacing: 0;
    }

    span {
      text-transform: uppercase;
    }
  }

  &__wrapper {
    @include flex-all-sb;
    gap: 20px;

    @include media(mobile-l) {
      flex-direction: column-reverse;
    }
  }

  .nav__inner {
    gap: 20px;

    @include media(mobile-l) {
      flex-direction: column;
    }
  }

  .nav__list {

    @include media(tablet) {
      gap: 20px;
      flex-wrap: wrap;
      max-width: 450px;
      justify-content: flex-end;
      margin: 0;
      margin-left: auto;
    }

    @include media(mobile-l) {
      margin: 0 auto;
      justify-content: center;
    }
  }

  .logo {
    @include media(mobile-l) {
      max-width: 200px;
    }
  }
}