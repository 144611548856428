@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.service {
  h2 {
    padding-left: 45px;
    margin-bottom: 35px;

    @include media(tablet) {
      padding-left: 0;
    }

    +p {
      max-width: 700px;
      margin-bottom: 60px;
      letter-spacing: 0.04em;
      padding-left: 45px;

      @include media(tablet) {
        padding-left: 0;
      }
    }
  }
}

.service-article {
  position: relative;

  img {
    width: 100%;
    min-height: 352px;
    margin-bottom: 65px;

    @include media(mobile-l) {
      margin-bottom: 35px;
    }
  }

  h3 {
    line-height: 1.4;

    @include media(mobile-l) {
      font-size: 28px;
      margin-bottom: 15px;
    }

    @include media(mobile-l) {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__content {
    padding-inline: 16px;

    span {
      font-weight: var(--fw-500);
      padding-right: 5px;
    }
  }

}