@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.testimonials {
  text-align: center;

  h2 {
    margin: 0 auto 20px;

    +p {
      margin: 0 auto 65px;
    }
  }

  h2,
  p {
    max-width: 600px;
  }

  &--services {
    .grid {

      p {
        font-size: 18px;
      }
    }
  }
}