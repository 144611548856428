@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.portfolio {
  h2 {
    margin-bottom: 32px;
    padding-left: 45px;

    @include media(tablet) {
      padding-left: 0;
    }

    +p {
      max-width: 850px;
      padding-left: 45px;

      @include media(tablet) {
        padding-left: 0;
      }
    }
  }

  &--portfolio-page,
  &--affairs {
    h2 {
      +p {
        max-width: 1200px;
      }
    }
  }

  &__text {
    margin-bottom: 0;
    padding-left: 45px;

    @include media(tablet) {
      padding-left: 0;
    }

    &:last-child {
      margin-bottom: 66px;
    }
  }
}