@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  padding-top: rem(159);
  background-color: var(--color-black);

  @include media(tablet-wide) {
    padding-top: 0;
  }

  &__inner {
    background-position: center 50px;
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(100%);
  }

  &__content {
    @include flex-v-center;
    position: relative;
    flex-direction: column;
    text-align: center;
    min-height: 915px;
    z-index: 1;

    @include media(tablet-wide) {
      justify-content: center;
      min-height: 700px;
    }

    @include media(tablet) {
      max-width: 100%;
    }

    h1 {
      font-style: italic;
      line-height: 1.4;
      color: var(--color-white);
      margin-bottom: rem(35);
    }

    span {
      font-style: normal;
    }

    p {
      font-size: 36px;
      letter-spacing: 0;
      line-height: 1.25;
      font-weight: var(--fw-300);
      color: var(--color-white);

      @include media(tablet) {
        max-width: 100%;
      }

      @include media(mobile-l) {
        font-size: 20px;
      }
    }
  }

  &--secondary {
    position: relative;
    padding-top: 0;
    background-color: var(--color-white);
    @include cover-background;

    &::before {
      content: "";
      position: absolute;
      background-color: var(--color-white);
      top: 0;
      bottom: 0;
      left: 50%;
      right: 0;

      @include media(tablet) {
        left: auto;
        inset: 0;
        background-color: rgba(255, 255, 255, .7)
      }
    }

    .hero__inner {
      min-height: 1075px;
      position: relative;
      padding-top: rem(100);

      @include media(tablet) {
        min-height: 700px;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: var(--color-white);
        top: 0;
        bottom: 0;
        left: 27%;
        right: 0;
        z-index: -1;

        @include media(tablet) {
          background-color: transparent;
        }
      }
    }

    .hero__content {
      padding-top: 30px;
      flex-direction: column;
      @include flex-h-center;
      z-index: 1;
      padding-block: 30px;

      @include media(tablet-wide) {
        margin: auto 0;
      }
    }

    .hero__block {
      text-align: left;
      @include flex-all-sb;
      padding-left: 305px;

      @include media(tablet-wide) {
        flex-direction: column-reverse;
        gap: 60px;
        padding-left: 0;
        transform: translateX(130px);
        align-items: flex-start;
      }

      @include media(tablet) {
        gap: 60px;
        transform: translateX(0);
        align-items: center;
      }
    }

    h1 {
      max-width: 850px;
      margin-bottom: 140px;
      line-height: 1;
      overflow: visible;

      @include media(tablet) {
        margin-bottom: 60px;
      }
    }

    p {
      font-size: 20px;
      max-width: 680px;
      line-height: 1.65;

      @include media(tablet) {
        text-align: center;
      }
    }

    p,
    h1,
    .btn {
      color: var(--color-black) !important;
    }

    .btn {
      border: none;
      font-style: italic;
      font-size: 32px;
      font-family: var(--font-family-title);
      text-transform: capitalize;
      letter-spacing: 0.08em;
      padding-left: 89px;
      margin-right: 64px;
      @include pseudo-vertical;

      @include media(tablet-wide) {
        margin-right: 0;
      }

      @include media(tablet-wide) {
        margin-right: 0;
      }

      &::before {
        width: 79px;
        height: 79px;
        border-radius: calc(var(--radius-main) * 8 - 4px);
        background-color: var(--color-white);
        border: 1px solid var(--color-black);
        left: 0;
        transition: background-color .2s;
      }

      &::after {
        background-image: var(--hero-arrow);
        width: 27px;
        height: 27px;
        left: 26px;
        @include contain-background;
        transition: filter .2s;
      }

      &:hover {
        background-color: transparent;

        @include media(tablet) {
          color: var(--color-black) !important;
        }

        &::before {
          background-color: var(--color-black);

          @include media(tablet) {
            background-color: var(--color-white);
          }
        }

        &:after {
          filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(7%) hue-rotate(198deg) brightness(107%) contrast(100%);

          @include media(tablet) {
            filter: none;
          }
        }
      }
    }
  }

  .btn {
    font-size: 28px;

    @include media(mobile-m) {
      font-size: 16px;
    }
  }

  &--blog {
    padding-top: rem(0);

    .hero__inner {
      padding-top: rem(159);
    }
  }

  @include media(mobile-l) {
    padding-top: 0;

    &__content {
      max-width: 100%;
      padding: 20px;
    }

    &__inner {
      min-height: 300px;
      padding: 0;
    }
  }
}