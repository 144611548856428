@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.social {
  @include flex-v-center;

  &__item {
    margin-right: 30px;
  }

  &__link {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;

    &::before {
      @include pseudo;
      @include contain-background;
      inset: 0;
    }

    &--facebook {
      &::before {
        background-image: var(--facebook);
      }

    }

    &--twitter {
      &::before {
        background-image: var(--twitter);
      }

    }

    &--instagram {
      &::before {
        background-image: var(--instagram);
      }

    }

    &--pinterest {
      &::before {
        background-image: var(--pinterest);
      }

    }

    &--linkedin {
      &::before {
        inset: 0;
        background-image: var(--linkedin);
      }

    }
  }
}