@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.gallery {
  &.section {
    padding-block: 64px 128px;

    @include media(tablet) {
      padding-block: rem(50);
    }
  }

  h2 {
    margin-bottom: 30px;
    padding-left: 45px;

    @include media(tablet) {
      padding-left: 0;
    }

    +p {
      padding-left: 45px;

      @include media(tablet) {
        padding-left: 0;
      }
    }
  }

  .gallery__wrapper {
    overflow: hidden;
    padding-inline: 45px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;

    @include media(tablet) {
      padding-inline: 20px;
    }
  }
}