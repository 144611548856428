@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.philosophy {
  &__wrapper {
    display: flex;

    @include media(tablet) {
      flex-direction: column-reverse;
    }
  }

  h2 {
    padding-bottom: 50px;

    @include media(tablet) {
      padding-bottom: 15px;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: rem(30);
    }
  }

  &__content {
    padding-block: 85px;
    max-width: 600px;

    @include media(tablet-wide) {
      max-width: 500px;
      padding-block: 65px;
    }

    @include media(tablet) {
      max-width: 100%;
      padding-block: 35px;
    }
  }

  &__inner {
    padding-inline: 50px;

    @include media(tablet-wide) {
      margin-right: 50px;
      padding-inline: 25px;
    }

    @include media(tablet) {
      margin-right: 0;
      padding-inline: 0px;
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    gap: 32px;

    @include media(mobile-m) {
      flex-wrap: wrap;
    }

    img {
      flex-shrink: 1;
      max-width: 300px;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;

      @include media(tablet-wide) {
        max-width: 250px;
      }

      @include media(mobile-m) {
        max-width: 100%;
        aspect-ratio: 2/1;
      }

    }

  }

  &__image {
    max-width: 600px;
    min-height: 965px;

    @include media(tablet) {
      max-width: 100%;
      min-height: auto;
      aspect-ratio: 14/7;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}