@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.portfolio-slider,
.affairs-slider {
  position: relative;

  &__list {
    @include media(tablet) {
      padding-bottom: 40px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    bottom: 152px;
    background-color: rgba(0, 0, 0, .5);
    @include slider-buttons;

    @include media(tablet) {
      display: none;
    }

    &::before {
      @include pseudo;
      @include slider-buttons-pseudo;

    }
  }

  .swiper-button-next {
    right: 76px;
  }

  .swiper-button-prev {
    left: 76px;

    &::before {
      content: "";
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }
  }
}