@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.blog-card {
  position: relative;
  width: 100%;

  &::before {
    @include pseudo;
    inset: 0;
    background-color: rgba(0, 0, 0, .25);
  }

  h3 {
    font-size: 64px;
    margin: 0 auto 20px;

    @include media(tablet-wide) {
      font-size: 44px;
    }

    @include media(mobile-l) {
      font-size: 34px;
      margin: 0 auto 10px;
    }

    @include media(mobile-s) {
      font-size: 28px;
    }
  }

  p {
    font-size: 36px;
    font-weight: var(--fw-300);
    margin-bottom: 60px;
    letter-spacing: 0;
    line-height: 1.65;

    @include media(tablet) {
      font-size: 32px;
    }

    @include media(mobile-l) {
      font-size: 20px;
      margin-bottom: 30px;
    }

    @include media(mobile-s) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  p,
  h3 {
    color: var(--color-white);
    text-align: center;
  }

  img {
    width: 100%;
    max-height: 728px;
    object-fit: cover;
    z-index: -1;

    @include media(tablet) {
      min-height: 300px;
    }
  }

  &__content {
    position: absolute;
    @include flex-v-center;
    flex-direction: column;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    padding-inline: 15px;
    border-radius: calc(var(--radius-main) - 4px);

    @include media(tablet) {
      bottom: auto;
      width: 100%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .btn {
    font-size: 28px;

    @include media(mobile-l) {
      font-size: 22px;
    }

    @include media(mobile-m) {
      font-size: 16px;
    }
  }
}