@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  @include flex-v-center;
  position: relative;
  justify-content: space-around;
  color: var(--color-white);

  &__inner {
    @include flex-all-sb;
    width: 100%;
    padding-block: 7px 7px;

    @include media(tablet) {
      .logo--header {
        display: none;
      }
    }

    .header & {
      @include media(tablet) {
        position: fixed;
        left: 0;
        top: 0;
        z-index: -1;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        color: var(--color-white);
        padding-block: calc(var(--header-height) * 1.2) 20px;
        width: 100%;
        height: calc(var(--vh));
        background-color: var(--color-dark);
        transform: translateX(150%);
        transition-property: transform;
        transition-duration: 0.4s;
      }
    }
  }

  &__list {
    @include flex-v-center;

    .header & {
      @include media(desktop) {
        margin-right: 10px;
      }

      @include media(tablet) {
        z-index: -1;
        flex-direction: column;
      }
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 30px;

      @include media(tablet-wide) {
        margin-right: 25px;

      }
    }

    .header & {
      @include media(tablet) {
        margin-bottom: 30px;
      }
    }
  }

  &__link {
    padding: 10px 0;
    display: inline-block;
    font-size: 18px;
    white-space: nowrap;
    text-transform: uppercase;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    transition: color .2s;

    &:hover {
      color: var(--color-gray);

      @include media(tablet) {
        color: inherit;
      }
    }
  }

  .true {
    @include media(tablet) {
      border-color: transparent;
    }
  }

  &__btns {
    @include flex-v-center;
    justify-self: end;

    .btn--user {
      margin-right: 20px;

      @include media(mobile-m) {
        margin: 0;
      }
    }

    @include media(tablet) {
      margin: 0 auto;
    }

    @include media(mobile-m) {
      flex-direction: column;
      gap: 30px;
    }
  }

  @include media(tablet) {
    .nav {
      &__list {
        margin: 0 auto;
      }

      &__item {
        margin-right: 0;
      }
    }

    &--footer {
      flex-direction: column;

      .logo {
        margin-bottom: 20px;
      }

      .nav {
        &__list {
          margin-bottom: 20px;

          @include media(mobile-m) {
            flex-direction: column;
          }
        }

        &__inner {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}