@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.blog-slider {
  position: relative;

  &__list {
    @include media(tablet) {
      padding-bottom: 40px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include slider-buttons;
    bottom: 200px;
    background-color: rgba(255, 255, 255, .5);

    @include media(tablet) {
      display: none;
    }

    &::before {
      @include pseudo;
      @include slider-buttons-pseudo;
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(220deg) brightness(107%) contrast(101%);
    }
  }

  .swiper-button-next {
    right: 76px;
  }

  .swiper-button-prev {
    left: 76px;

    &::before {
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }
  }
}