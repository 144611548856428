@mixin slider-buttons {
  position: absolute;
  top: auto;
  width: 48px;
  height: 48px;
  backdrop-filter: blur(5px);
  border-radius: calc(var(--radius-main) * 8 - 4px);
}

@mixin slider-buttons-pseudo {
  width: 24px;
  height: 24px;
  background-image: var(--arrow);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}