@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.article {
  padding-top: 160px;

  @include media(tablet) {
    padding-top: 100px;
  }

  .container {
    max-width: 1246px;
  }

  .section {
    padding-block: 64px 100px;
  }

  h1 {
    font-family: var(--font-family-secondary);
    font-size: 52px;
    margin-bottom: 25px;

    @include media(tablet) {
      font-size: 38px;
    }

    @include media(mobile-s) {
      font-size: 32px;
    }

    +p {
      margin-bottom: 30px;
    }
  }

  h1,
  a {
    font-family: var(--font-family-secondary);
    text-transform: uppercase;
  }

  a {
    position: relative;
    display: inline-block;
    font-size: 35px;
    padding-left: 54px;
    margin-bottom: 60px;

    @include media(tablet) {
      font-size: 20px;
      margin-bottom: 30px;
    }

    &::before {
      @include pseudo;
      top: 50%;
      left: 12px;
      transform: translateY(-50%) rotate(180deg);
      width: 24px;
      height: 24px;
      background-image: var(--arrow);
      filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(40deg) brightness(113%) contrast(100%);
    }
  }

  img {
    min-height: 728px;
    width: 100%;

    @include media(tablet) {
      min-height: 400px;
    }
  }

  h2 {
    margin-bottom: 32px;

    @include media(tablet) {
      font-size: 28px;
    }

    @include media(mobile-s) {
      font-size: 24px;
    }
  }

  &__list {
    padding-left: 30px;

    @include media(mobile-s) {
      padding-left: 15px;
    }
  }

  &__item {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 35px;
    list-style-position: outside;
    list-style-type: decimal;

    @include media(mobile-l) {
      font-size: 16px;
    }
  }

  &__content {
    p {
      &:not(:last-of-type) {
        margin-bottom: 35px;
      }
    }
  }
}