@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-size: 20px;
  font-weight: var(--fw-400);
  font-family: var(--font-family-primary);
  color: var(--color-black);
}

h1 {
  font-family: var(--font-family-title);
  text-transform: capitalize;
  font-size: 100px;

  @include media(mobile-l) {
    font-size: 54px;
  }

  @include media(mobile-s) {
    font-size: 34px;
  }
}

h2,
.title {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-size: 52px;
  line-height: 1.35;
  text-align: left;
  color: inherit;

  @include media(tablet) {
    padding-left: 0;
    font-size: 42px;
  }

  @include media(mobile-m) {
    font-size: 28px;
  }

  +p {
    letter-spacing: 0.03em;
    margin-bottom: 64px;

    @include media(tablet) {
      padding-left: 0;
    }
  }
}

h3 {
  font-family: var(--font-family-secondary);
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 32px;
}

p {
  font-size: 20px;
  line-height: 1.65;
  letter-spacing: 0.03em;
  font-weight: var(--fw-400);
  color: var(--color-black);

  @include media(tablet) {
    font-size: 18px;
  }

  @include media(mobile-l) {
    font-size: 16px;
  }
}