@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.gallery-slider {
  position: relative;
  overflow: visible;

  &__list {
    @include media(tablet) {
      padding-bottom: 40px;
    }
  }

  &__slide {
    max-width: 625px;
    max-height: 840px;
  }

  h3,
  p {
    padding-inline: 15px;

    @include media(tablet) {
      padding-inline: 0;
    }
  }

  h3 {
    font-size: 28px;
  }

  img {
    max-height: 840px;
    height: 100%;
    user-select: none;
    margin-bottom: 60px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include slider-buttons;
    background-color: rgba(0, 0, 0, .7);
    top: 50%;
    transform: translateY(-50%);

    @include media(tablet) {
      display: none;
    }

    &::before {
      @include pseudo;
      @include slider-buttons-pseudo;
    }
  }

  .swiper-button-next {
    right: -20px;
  }

  .swiper-button-prev {
    left: -20px;

    &::before {
      content: "";
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }
  }
}