@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.services {
  h2 {
    padding-left: 45px;
    margin-bottom: 15px;

    @include media(tablet) {
      padding-left: 0;
    }

    +p {
      padding-left: 45px;

      @include media(tablet) {
        padding-left: 0;
      }
    }
  }

  h3 {
    font-size: 32px;
    line-height: 1.35;
    margin-bottom: 15px;

    @include media(tablet) {
      font-size: 28px;
    }

    @include media(mobile-l) {
      font-size: 20px;
    }
  }

  .grid__item {
    position: relative;
    padding-left: 96px;

    @include media(mobile-l) {
      padding-left: 0;
      padding-top: 76px;
    }

    &::before {
      content: "";
      position: absolute;
      @include contain-background;
      width: 64px;
      height: 64px;
      top: 0;
      left: 0;

      @include media(mobile-l) {
        width: 54px;
        height: 54px;
      }
    }

    &--consultation {
      &::before {
        background-image: var(--consultation);
      }
    }

    &--wardrobe {
      &::before {
        background-image: var(--wardrobe);
      }
    }

    &--shopping {
      &::before {
        background-image: var(--shopping);
      }
    }

    &--services {
      &::before {
        background-image: var(--services);
      }
    }

    p {
      font-size: 18px;
    }
  }

  .btn--more {
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 17px;

    @include media(mobile-m) {
      font-size: 16px;
    }
  }
}