@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  &__top {
    position: relative;
    @include cover-background;
    padding-block: 90px;

    &.overlay {
      &::before {
        content: "";
        background: var(--contact-gradient);
      }
    }
  }

  .container {
    max-width: 1290px;
  }

  &__wrapper {
    @include flex-sb;
    gap: 40px;

    @include media(tablet) {
      flex-wrap: wrap;
    }
  }

  &__inner {
    color: var(--color-white);
    max-width: 490px;
    z-index: 1;
    font-size: 20px;

    @include media(tablet) {
      max-width: 100%;
    }
  }

  h2 {
    margin-bottom: 10px;

    +p {
      margin-bottom: 67px;
    }
  }

  p,
  a {
    color: var(--color-white);
  }

  a {
    font-weight: var(--fw-500);

    @include media(mobile-m) {
      font-size: 16px;
    }
  }

  span {
    display: block;
  }

  &__item {
    position: relative;
    padding-left: 83px;
    @include pseudo-vertical;

    &::before,
    &::after {
      @include contain-background;
    }

    &::before {
      background-color: var(--color-white);
      width: 67px;
      height: 67px;
      left: 0;
    }

    &::after {
      width: 36px;
      height: 36px;
      left: 15px;
    }

    &--phone {
      &::after {
        background-image: url('../images/icons/phone.png');
      }
    }

    &--mail {
      &::after {
        background-image: url('../images/icons/mail.png');
      }
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__form {
    max-width: 600px;
    padding: 40px;
    background-color: var(--color-white);
    width: 100%;
    z-index: 1;
    line-height: 1;

    @include media(tablet) {
      margin: 0 auto;
    }

    @include media(mobile-l) {
      padding: 40px 20px;
      max-width: 100%;
    }
  }

  &__label {
    display: inline-block;
    width: 100%;

    &--short {
      max-width: 250px;
      width: 100%;

      @include media(mobile-l) {
        max-width: 100%;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      margin-right: 15px;
    }

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  input,
  textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--color-black);
    padding-block: 12px 11px;

    &::placeholder {
      font-weight: var(--fw-500);
      color: var(--color-black);
      font-size: 20px;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  textarea {
    min-height: 191px;
    resize: vertical;
  }

  img {
    width: 100%;
    min-height: 470px;
  }

  .btn {
    margin: 0 auto;
    font-size: 24px;

    @include media(mobile-m) {
      font-size: 16px;
    }
  }
}