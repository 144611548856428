@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {

  &__inner {
    position: relative;
    position: relative;
    display: flex;
    justify-items: flex-end;
    flex-direction: column;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 967px;
    padding-top: 210px;

    &::after {
      @include pseudo;
      max-width: 910px;
      width: 100%;
      top: 5px;
      right: 10;
      min-height: 205px;
      z-index: -1;
      @include cover-background;
      background-image: url('../images/home/charlotte-sinclair.svg')
    }



    @include media(tablet-wide) {
      padding-top: 180px;

      &::before {
        @include pseudo;
        background-color: rgba(255, 255, 255, .6);
        inset: 0;
        z-index: 1;
      }

      &::after {
        @include contain-background;
        max-width: 710px;
        min-height: 160px;
      }
    }

    @include media(tablet) {
      min-height: 767px;

      &::before {
        background-color: rgba(255, 255, 255, .8);
      }
    }

    @include media(mobile-l) {
      min-height: 567px;
      padding-top: 100px;

      &::after {
        max-width: 400px;
        min-height: 90px;
      }
    }

    @include media(mobile-s) {
      background-position: bottom center;
      padding-top: 70px;

      &::after {
        right: auto;
        left: 15px;
        max-width: 300px;
        min-height: 50px;
      }
    }
  }

  &__content {
    @include flex;
    text-align: right;
    flex-direction: column;
    align-items: flex-end;
    max-width: 650px;
    margin-left: auto;
    padding-inline: 48px;
    margin-bottom: 32px;
    z-index: 1;

    @include media(mobile-l) {
      max-width: 100%;
      text-align: left;
      align-items: flex-start;
      padding-inline: 15px;
    }
  }

  h2 {
    text-align: right;
    margin-bottom: 32px;
  }

  p {
    letter-spacing: 0.03em;
    margin-bottom: 33px;
  }

  .container {
    @include media(mobile-l) {
      padding: 0;
    }
  }
}